.tiled-section {
    display: flex;
    justify-content: space-between;
    background-color: #2c3e50; /* Dark teal background */
    padding: 2%;
}

.tile {
    flex: 1;
    background-color: #ffffff;
    border-radius: 12px;
    text-align: center;
    padding: 2%;
    margin: 2%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: #2c3e50; /* Dark teal text color */
}

.tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.tile-icon {
    width: 50px; /* Adjust the size as needed */
    height: 50px;
    /* margin-bottom: 6px; */
}

.tile-title {
    font-size: 24px;
    margin-bottom: 10px;
}

.tile-description {
    font-size: 16px;
}