/* Membership.css */
.membership {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
    padding: 5% 0;
}

.membership-image {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.membership-main-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.membership-header {
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}

.membership-header h1 {
    font-size: 36px;
    color: #003366;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}

/* General CSS for section titles */
.membership h2, .membership h3 {
    font-family: 'Arial', sans-serif;
}