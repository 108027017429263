/* MembershipForm.css */
.membership-form-section {
    padding: 20px;
    background-color: #f6f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.membership-form-section h2 {
    font-size: 28px;
    color: #003366;
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    border-bottom: 2px solid #003366;
    padding-bottom: 5px;
}
.membership-form-section a.download-link {
    display: inline-block;
    padding: 10px 20px;
    margin: 10px;
    text-decoration: none;
    color: #fff;
    background-color: var(--second-color);
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.membership-form-section a.download-link:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px);
}

/* Optional: Add the same animation effect as in the events tab */
.membership-form-section a.download-link::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #fff;
    transition: width 0.3s;
}

.membership-form-section a.download-link:hover::after {
    width: 100%;
    transition: width 0.3s;
}