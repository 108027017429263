/* MembershipBenefits.css */
.membership-benefits {
    padding: 20px;
    background-color: #f6f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.membership-benefits h2 {
    font-size: 28px;
    color: #003366;
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    border-bottom: 2px solid #003366;
    padding-bottom: 5px;
}

.membership-benefits ul {
    list-style: none;
    padding: 0;
}

.membership-benefits li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    font-size: 18px;
    color: #333;
    margin-right: 8px;
}

.membership-benefits svg {
    margin-right: 8px;
}

.membership-benefits .tick-icon {
    color: #2ecc71;
    margin-right: 12px;
    font-size: 22px;
}
