.footer {
    background-color: #2c3e50; /* Deep teal background */
    color: #ecf0f1; /* Light gray text */
    padding: 20px 0;
    text-align: center;
}

.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer-section {
    margin: 10px;
}

.footer-section h4 {
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: uppercase;
    color: #f39c12; /* Warm orange title color */
}

.footer-section p {
    margin: 5px 0;
    font-size: 16px;
}

.footer-section .social a {
    display: inline-block;
    margin-top: 10px;
}

.facebookImg {
    width: 40px; /* Set icon size to 40x40 pixels */
    height: 40px;
    transition: transform 0.3s, filter 0.3s;
    filter: brightness(0) invert(1); /* White icon */
}

.footer-section .social img {
    width: 40px; /* Set icon size to 40x40 pixels */
    height: 40px;
    transition: transform 0.3s, filter 0.3s;
    filter: brightness(0) invert(1); /* White icon */
}

.footer-section .social img:hover {
    transform: scale(1.1);
    filter: brightness(0) invert(0.7); /* Slightly lighter on hover */
}

.icons {
    margin-top: 10px;
    color: rgb(162, 160, 160)
}