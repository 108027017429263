.contact-us-container {
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    padding-bottom: 400px;
  }
  
  .contact-us-container h1 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 2.5rem;
    color: #003366;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  
  .general-contact, .people-contact {
    background-color: #f4f4f4;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .general-contact h2, .people-contact h2 {
    font-size: 28px;
    color: #003366;
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    border-bottom: 2px solid #003366;
    padding-bottom: 5px;
  }
  
  .contact-card {
    margin-bottom: 20px;
    padding: 15px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .contact-card h3 {
    margin-top: 0;
    color: #003366;;
    font-size: 1.2rem;
  }
  
  .contact-card p {
    margin: 5px 0;
  }
  
  a {
    color: #003366;;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }

  .facebook-link {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .facebook-link p {
    margin: 0;
    margin-right: 10px;
  }
  
  .facebook-icon {
    width: 30px;
    height: 30px;
  }
  