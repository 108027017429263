/* FacilitiesPage.css */

.facilities-page {
    margin: 0 10%;
    padding: 20px;
  }
  
  .facilities-page h1 {
    text-align: center;
    margin-bottom: 60px;
    font-size: 2.5rem;
    color: #003366;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  
  .facility-tile {
    display: flex;
    align-items: center;
    background-color: #f6f5f5;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px; /* Set a smaller max width for the tile */
    margin-left: auto;  /* Center the tile */
    margin-right: auto; /* Center the tile */
  }
  
  .facility-tile.reverse {
    flex-direction: row-reverse;
  }
  
  .image-container {
    flex: 1;
    margin-right: 20px;
    max-width: 50%; /* Ensure the image container doesn't exceed half the tile width */
  }
  
  .image-container img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .text-container {
    flex: 2;
  }
  
  .text-container h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .text-container p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
  }
  
  .become-member-button {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #fff;
    background-color: var(--second-color);
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border: none; /* Remove any default outline */
  }
  
  .become-member-button:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px); /* Jump effect */
  }
  