.sponsor-carousel {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%; /* Full width of the container */
  height: 150px; /* Adjust height as needed */
  background-color: #ffffff; /* White background */
  box-sizing: border-box; /* Include padding/margin in width */
  display: flex;
  align-items: center; /* Vertically center items */
}

.sponsor-carousel-inner {
  display: flex;
  animation: scroll 3000s linear infinite; /* Adjust duration as needed */
  align-items: center; /* Vertically center items */
}

.sponsor-item {
  display: inline-block;
  padding: 20px; /* Adjust padding as needed */
}

.sponsor-item img {
  max-width: 150px; /* Default size for images */
  height: auto;
}

/* Specific sizes for certain sponsors */
.sponsor-item.large img {
  max-width: 200px; /* Larger size for specific sponsors */
}

.sponsor-item.small img {
  max-width: 120px; /* Smaller size for specific sponsors */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}


.sponsor-title {
  font-size: 24px; /* Larger description size */
  color: #666;
  margin: 20px 20px;
  margin-top: 80px;
  position: relative;
  background-color: white;
}
