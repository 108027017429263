.info-section {
    background-color: white;
    padding: 40px; /* Adjust padding if needed */
    padding-left: 0px;
    text-align: center;
    position: relative;
    
  }

  .info-header {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 50px;
  }
  
  .info-logo {
    max-width: 150px; /* Larger logo size */
    height: auto;
  }
  
  .info-title {
    font-size: 48px; /* Larger title size */
    margin: 20px 20px;
  }
  
  .info-description {
    font-size: 24px; /* Larger description size */
    color: #666;
    margin: 20px 20px;
    margin-top: 80px;
  }
  
  .info-image {
    margin: 0; /* Remove margin to align image to edge */
    padding: 0; /* Remove padding to align image to edge */
    width: 100vw; /* Full viewport width */
    overflow: hidden; /* Hide any overflow */
  }
  
  .info-main-image {
    padding-left: 0px;
    width: auto;
    height: auto;
    display: block;
    position: relative;
    margin: 0; /* Ensure no margin around the image */
  }
  