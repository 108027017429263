/* MembershipPricing.css */
.membership-pricing {
    padding: 20px;
    background-color: #f6f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
}

.membership-pricing h2 {
    font-size: 28px;
    color: #003366;
    margin-bottom: 15px;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    border-bottom: 2px solid #003366;
    padding-bottom: 5px;
}

.membership-pricing p {
    font-size: 24px;
    color: #000000;
    font-weight: bold;
    margin-top: 10px;
}
