.reservation_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

iframe {
    border: 0;
    width: 100%;
    scrolling: "no";
    height: 1500px;
}