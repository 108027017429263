.reservation-container {
    max-width: 800px; 
    margin: 60px auto 0 auto; 
}

.reservation-container > h2 {
    text-align: center;
    margin-top: 60px;
}

.download-link {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 20px;
    text-decoration: none;
    color: #fff;
    background-color: var(--second-color);
    border-radius: 5px;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border: none; /* Remove any default outline */
  }
  
  .download-link:hover {
    background-color: var(--hover-color);
    transform: translateY(-2px); /* Jump effect */
  }