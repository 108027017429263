:root {
  --header-height: 3.5rem;
  --first-color: hsl(111, 64%, 22%);
  --second-color: hsl(132, 30%, 62%);
  --hover-color: #578574;
  --title-color: hsl(0, 6%, 21%);
  --text-color: hsl(0, 5%, 15%);
  /* --body-color: hsl(138, 13%, 85%); */
  --body-color: hsl(0, 0%, 100%);
  --body-font: "Cabin", sans-serif;
  --second-font: "Dancing Script", cursive;
  --h2-font-size: 1.5rem;
  --small-font-size: 0.813rem;
  --font-semi-bold: 600;
  --z-tooltip: 10;
  --z-fixed: 100;
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
  font-family: var(--body-font);
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.main-content {
  padding-top: 6.5rem; /* Account for navbar height */
  min-height: 130vh; /* Ensure the main content takes up full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.container {
  width: 100%;
}
