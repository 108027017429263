.event-list-container {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start; /* Align items to the top */
    gap: 20px; /* Space between the event list and contact section */
    padding: 20px;
    max-width: 100%; /* Ensure container does not exceed screen width */
    box-sizing: border-box; /* Include padding in width/height calculations */
    overflow: visible; /* Ensure the content inside isn't cut off */
}

.event_list {
    display: flex;
    flex-direction: column; /* Arrange the cards in a single column */
    gap: 20px; /* Space between the cards */
    width: 70%; /* Adjust width to occupy majority of the container space */
    box-sizing: border-box; /* Include padding in width/height calculations */
    padding-right: 20px; /* Add some padding on the right for spacing */
    overflow: visible; /* Ensure tiles are not cut off */
    margin-left: 25%;
}

.events__title {
    font-size: 36px;
    color: #003366;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}


.contact-section h3 {
    margin-bottom: 10px;
    place-self: center;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.contact-section p {
    margin-bottom: 5px;
}


/* Smaller screens */
@media (max-width: 768px) {
    .event_list {
        margin: 10%
    }
}