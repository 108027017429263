.event-card {
    background-color: #f6f5f5;
    margin: 15px 0; /* Spacing between cards */
    padding: 20px;
    border-radius: 10px; /* Rounded edges */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 700px; /* Adjust based on your design */
    position: relative;
    overflow: hidden; /* Ensures animations stay within bounds */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover {
    transform: scale(1.02); /* Slightly enlarges the card on hover */
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
}

.event-card--disabled {
    background-color: #f0f0f0; /* Light grey background for past events */
    color: #999; /* Grey color for text */
    opacity: 0.7; /* Slightly faded */
    pointer-events: none; /* Disable interactions */
}

.event-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.event-details {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.event-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.event-date, .event-time, .event-location {
    color: #888;
    margin-bottom: 5px;
}

.event-description {
    margin-top: 30px;
}

.register-button {
    position: absolute;
    right: 20px; /* Position the button on the right */
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--second-color); /* Darker blue to highlight on card hover */
    color: white; /* Default button text color */
    border: none;
    border-radius: 5px; /* Rounded edges */
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.event-card:hover .register-button {
    background-color: #578574; /* Default button background color */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Shadow to highlight effect */
}

.event-card.disabled .register-button {
    background-color: #b0b0b0; /* Color for disabled button */
    color: #6c757d; /* Text color for disabled button */
}

.event-card.disabled:hover .register-button {
    background-color: #9a9a9a; /* Slightly darker color for hover effect on disabled button */
    box-shadow: none; /* No shadow on hover for disabled button */
}