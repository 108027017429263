/* Navbar.css */

.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #2c3e50;
    z-index: var(--z-fixed);
}

.nav {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: var(--header-height);
    margin: 1rem;
}

.nav__logo {
    color: rgb(231, 227, 227);
    transition: color 0.4s;
    font-size: var(--h2-font-size);
    font-family: var(--second-font);
}

.nav__toggle,
.nav__close {
    font-size: 1.5rem;
    color: var(--second-color);
    cursor: pointer;
}

.nav__cta {
background-color: var(--second-color);
color: var(--title-color);
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s ease;
padding: 0.75rem 1.5rem;
}

@media screen and (max-width: 1150px) {
.nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: hsla(0, 0%, 6%, 0.2);
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    width: 80%;
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
}
}
@media screen and (max-width: 300px) {
.nav__menu {
    padding: 4rem 1rem 2rem;
}

.nav__list {
    row-gap: 1rem;
}

.nav__link,
.nav__cta {
    font-size: var(--smaller-font-size);
}

.nav__cta {
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 1rem;
    width: auto;
    white-space: nowrap;
}
}

.nav__list {
display: flex;
flex-direction: column;
row-gap: 2.5rem;
}

.nav__link {
color: rgb(231, 227, 227);
font-weight: var(--font-semi-bold);
transition: color 0.4s;
}

.nav__link:hover {
color: var(--second-color);
}

.nav__cta:hover {
    color: #2c3e50;
}

.nav__close {
position: absolute;
top: 1rem;
right: 1.5rem;
}

.show-menu {
right: 0;
}
.nav__listt {
display: flex;
flex-direction: row;
column-gap: 2.5rem;
}

.nav__menu_mobile {
position: fixed;
top: 0;
right: -100%;
background-color: hsla(0, 5%, 4%, 0.2);
backdrop-filter: blur(20px);
-webkit-backdrop-filter: blur(20px);
width: 80%;
height: 100%;
padding: 6rem 3rem 0;
transition: right 0.4s;
}
@media screen and (min-width: 1150px) {
.nav {
    height: calc(var(--header-height) + 1rem);
}
.nav__toggle,
.nav__close {
    display: none;
}

.nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
}
}